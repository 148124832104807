<template>
  <v-select
    dense
    outlined
    hide-details
    multiple
    :items="options"
    v-model="profileArr"
    @blur="handleClose"
  />
</template>

<script>
import { requests } from '@/plugins/Amplify'

import {
  REVISOR_CODE,
  DIRIGENTE_CODE,
  SUPERVISOR_CODE,
  PESQUISADOR_CODE,
  ADMINISTRADOR_CODE,
  LEARN_PAGES_ARTWORK_CODE,
  LEARN_PAGES_REVIEW_CODE
} from '@/constants'

export default {
  props: {
    value: { default: [] },
    user: {},
    icon: {},
    label: { default: '' },
    outlined: { default: false },
    readonly: { default: false },
    hideDetails: { default: true }
  },

  data () {
    return this.prepareProfiles(this.value)
  },

  methods: {
    handleClose () {
      const oldProfiles = this.profiles
      const newProfiles = this.profileArr

      if (this.isEqual(newProfiles, oldProfiles)) return
      if (!newProfiles.length) {
        this.profileArr = oldProfiles
        return
      }

      const profilesToAdd = newProfiles.filter(profile => !oldProfiles.includes(profile))
      const profilesToRemove = oldProfiles.filter(profile => !newProfiles.includes(profile))

      Promise.all([
        ...profilesToAdd.map(profile => this.addProfile(profile)),
        ...profilesToRemove.map(profile => this.removeProfile(profile))
      ]).then(() => {
        this.profiles = [...this.profileArr]
      })
    },

    isEqual: (source, target) => JSON.stringify(source) === JSON.stringify(target),

    prepareProfiles (value) {
      const profiles = Array.isArray(value) ? value : []

      const profileArr = []
      if (profiles.includes(ADMINISTRADOR_CODE)) profileArr.push(ADMINISTRADOR_CODE)
      if (profiles.includes(SUPERVISOR_CODE)) profileArr.push(SUPERVISOR_CODE)
      if (profiles.includes(REVISOR_CODE)) profileArr.push(REVISOR_CODE)
      if (profiles.includes(DIRIGENTE_CODE)) profileArr.push(DIRIGENTE_CODE)
      if (profiles.includes(LEARN_PAGES_ARTWORK_CODE)) profileArr.push(LEARN_PAGES_ARTWORK_CODE)
      if (profiles.includes(LEARN_PAGES_REVIEW_CODE)) profileArr.push(LEARN_PAGES_REVIEW_CODE)
      if (!profileArr.length) profileArr.push(PESQUISADOR_CODE)

      const options = [
        { value: ADMINISTRADOR_CODE, text: 'Administrador' },
        { value: SUPERVISOR_CODE, text: 'Supervisor' },
        { value: REVISOR_CODE, text: 'Revisor' },
        { value: LEARN_PAGES_ARTWORK_CODE, text: 'Pesquisas - Arte Finalista' },
        { value: LEARN_PAGES_REVIEW_CODE, text: 'Pesquisas - Revisor' }
      ]

      options.push(
        profiles.includes(DIRIGENTE_CODE)
          ? { value: DIRIGENTE_CODE, text: 'Dirigente' }
          : { value: PESQUISADOR_CODE, text: 'Pesquisador' }
      )

      return { profileArr, profiles, options }
    },

    ignoreRequest: (profile) => !profile || [DIRIGENTE_CODE, PESQUISADOR_CODE].includes(profile),

    async addProfile (profile) {
      if (this.ignoreRequest(profile)) return
      return requests.setUserProfile(this.user, profile)
    },

    async removeProfile (profile) {
      if (this.ignoreRequest(profile)) return
      return requests.removeUserProfile(this.user, profile)
    }
  },

  watch: {
    profiles (profiles) {
      if (this.isEqual(this.value, profiles)) return
      this.$emit('input', profiles)
    },

    value (value) {
      if (this.isEqual(value, this.profiles)) return
      Object.assign(this, this.prepareProfiles(value))
    }
  }
}
</script>
