<template>
  <v-autocomplete
    dense
    :items="profiles"
    :label="i18n(label)"
    :outlined="outlined"
    :autofocus="autofocus"
    :clearable="true"
    class="pt-0"
    :prepend-inner-icon="icon"
    :hide-details="hideDetails"
    v-model="profile"
  />
</template>

<script>
import {
  REVISOR_CODE,
  DIRIGENTE_CODE,
  SUPERVISOR_CODE,
  ADMINISTRADOR_CODE,
  LEARN_PAGES_ARTWORK_CODE,
  LEARN_PAGES_REVIEW_CODE
} from '@/constants'

const onlyNumber = (str) => isNaN(str) ? null : Number(str)

export default {
  props: {
    icon: {},
    value: {},
    label: { default: 'field.profile' },
    outlined: { default: false },
    autofocus: {},
    hideDetails: { default: true }
  },

  data () {
    return {
      profile: onlyNumber(this.value),
      profiles: [
        { value: ADMINISTRADOR_CODE, text: 'Administrador' },
        { value: SUPERVISOR_CODE, text: 'Supervisor' },
        { value: REVISOR_CODE, text: 'Revisor' },
        { value: DIRIGENTE_CODE, text: 'Dirigente' },
        { value: LEARN_PAGES_ARTWORK_CODE, text: 'Arte Finalista - Pesquisas' },
        { value: LEARN_PAGES_REVIEW_CODE, text: 'Revisor - Pesquisas' }
      ]
    }
  },

  watch: {
    profile () {
      const profile = onlyNumber(this.profile)
      const changed = profile !== onlyNumber(this.value)
      if (changed) this.$emit('input', profile)
    },

    value () {
      const value = onlyNumber(this.value)
      const changed = value !== onlyNumber(this.profile)
      if (changed) this.profile = value
    }
  }
}
</script>
